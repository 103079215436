import { checkPhoneExists } from '@/services/validations-helper'

import {
    required,
    numeric,
    minLength,
    maxLength
} from 'vuelidate/lib/validators';

export const validationRules = {
    country: {
        required,
        objectStructure(value) {
            if (!value.value || !value.iso) { return false }
            return true
        }
    },
    phone_number: {
        required,
        numeric,
        minLength: minLength(6),
        maxLength: maxLength(20),
        async isUniquePhoneNumber() {
            if (!this.country.value || !this.phone_number) return true
            if (!this.$v.phone_number.required || !this.$v.phone_number.minLength || !this.$v.phone_number.maxLength) return true
            try {
                await checkPhoneExists({
                    phone_code: this.country.value,
                    phone: this.phone_number
                })
                return true
            } catch (error) {
                return false
            }
        }
    }
}
